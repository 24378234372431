import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import GoogleTagManager from './components/GoogleTagManager';
import AppLanding        from './App-00-landing';
import AppLogin          from './App-00-login';
import AppLoginEnter     from './App-00-login-enter';
import AppRegistration   from './App-00-registration';
import AppRecover        from './App-00-recover';
import AppRedefine       from './App-00-redefine';
import AppPurchase       from './App-01-purchase';
import AppPayment        from './App-02-payment';
import AppParticipants   from './App-03-participants';
import AppSuccess        from './App-04-success';
import AppMyNumber       from './App-05-my-number';
import AppAdminMenu      from './App-100-admin-menu';
import AppAdminEvents    from './App-101-admin-events';

const App = () => (
  <div>
    <GoogleTagManager />
    <Routes>
      <Route path="/"              Component={AppLanding}        />
      <Route path="/home"          Component={AppLanding}        />
      <Route path="/:eventName"    Component={AppLanding}        />
      <Route path="/login"         Component={AppLogin}          />
      <Route path="/login-enter"   Component={AppLoginEnter}     />
      <Route path="/registration"  Component={AppRegistration}   />
      <Route path="/recover"       Component={AppRecover}        />
      <Route path="/redefine"      Component={AppRedefine}       />
      <Route path="/purchase"      Component={AppPurchase}       />
      <Route path="/payment"       Component={AppPayment}        />
      <Route path="/participants"  Component={AppParticipants}   />
      <Route path="/success"       Component={AppSuccess}        />
      <Route path="/numero"        Component={AppMyNumber}       />
      <Route path="/admin"         Component={AppAdminMenu}      />
      <Route path="/admin-events"  Component={AppAdminEvents}    />
    </Routes>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 //<React.StrictMode>
    <BrowserRouter>
       <App />
    </BrowserRouter>
 //</React.StrictMode>
);

//import reportWebVitals       from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

export default App;

